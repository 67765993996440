import { ChakraProps, Heading, Link } from "@chakra-ui/react";
import { ReactNode } from "react";

export function H1({
  children,
  ...rest
}: { children: ReactNode } & ChakraProps) {
  return (
    <Heading size={"lg"} color="cyan.900" mb={8} {...rest}>
      {children}
    </Heading>
  );
}

export function H2({
  children,
  ...rest
}: { children: ReactNode } & ChakraProps) {
  return (
    <Heading mt={8} size={"md"} color="cyan.800" {...rest}>
      {children}
    </Heading>
  );
}

export function ExternalLink({
  href,
  children,
  as,
}: {
  href?: string;
  children: ReactNode;
  as?: React.ElementType;
}) {
  return (
    <Link color={"blue.600"} target="_blank" href={href}>
      {children}
    </Link>
  );
}
