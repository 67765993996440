import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Layout from "./layout";
import { useEffect } from "react";

export function links() {
  return [
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap",
    },
  ];
}

export default function Index() {
  const navigate = useNavigate();
  useEffect(() => {
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
      navigate(path);
    }
  }, [navigate]);
  return (
    <Container maxW={"4xl"}>
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 20, md: 36 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          A novel cloud platform
          <br />
          <Text
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            as={"span"}
            color={"teal.400"}
          >
            for computational science
          </Text>
        </Heading>
        <Text fontSize={{ base: "1xl" }} color={"gray.500"}>
          Publish your computational task to SATH
          <br />
          and anyone in the world can contribute their CPU/GPU
        </Text>
        <Text color={"gray.500"}></Text>
        <Stack
          direction={"column"}
          spacing={3}
          align={"center"}
          alignSelf={"center"}
          position={"relative"}
        >
          <RouterLink to="/signup">
            <Button colorScheme={"teal"} px={6}>
              Get Started
            </Button>
          </RouterLink>

          {/* <Button variant={"link"} colorScheme={"blue"} size={"sm"}>
              Learn more
            </Button> */}
        </Stack>
      </Stack>
    </Container>
  );
}
