import { useToast } from "@chakra-ui/react";

export function useUnderconstructionToast() {
  const toast = useToast();

  return function () {
    toast({
      title: "Under Construction",
      description: "This feature is not implemented yet",
      status: "warning",
      duration: 3000,
      position: "top",
      isClosable: true,
    });
  };
}

export function useSuccessToast() {
  const toast = useToast();
  return function (title: string, description?: string) {
    toast({
      title: title,
      description: description,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };
}
