import { useEffect, useState } from "react";
import { clientApi } from "./http";
import { useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";

export interface User {
  name: string;
  email: string;
  avatar?: string;
}

async function fetchUser(): Promise<User | null> {
  let sessionData = sessionStorage.getItem("user-data");
  if (sessionData) {
    try {
      let jsonData = JSON.parse(sessionData);
      if (jsonData && jsonData.name && jsonData.email) {
        return jsonData;
      }
    } catch (e) {
      // TODO
    }
  }
  try {
    const res = await clientApi.get<User>("/users/profile", {
      validateStatus: (status) => status === 200 || status === 401,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch {
    // TODO
  }
  return null;
}

export function useOptionalUser() {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  useEffect(() => {
    fetchUser().then((data) => {
      if (data) {
        sessionStorage.setItem("user-data", JSON.stringify(data));
        setUser(data);
      } else {
        setUser(null);
      }
    });
  }, []);
  return user;
}

export function useUser() {
  let user = useOptionalUser();
  invariant(user);
  return user;
}

export enum OperatingSystem {
  Unknown,
  Windows,
  Linux,
  MacOS,
  Unix,
}

export const getOperatingSystem = () => {
  let os = OperatingSystem.Unknown;

  if (navigator.userAgent.indexOf("Win") != -1) os = OperatingSystem.Windows;
  else if (navigator.userAgent.indexOf("Mac") != -1) os = OperatingSystem.MacOS;
  else if (navigator.userAgent.indexOf("Linux") != -1)
    os = OperatingSystem.Linux;
  else if (navigator.userAgent.indexOf("X11") != -1) os = OperatingSystem.Unix;

  return os;
};
