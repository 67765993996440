import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { clientApi } from "~/utils/http";

export default function UserMenuButton({
  size,
  avatar,
  email,
  name,
  ...props
}: Omit<AvatarProps, "avatar"> & {
  avatar?: string;
  name: string;
  email: string;
}) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded={"full"}
        variant={"link"}
        cursor={"pointer"}
        minW={0}
      >
        <Avatar
          size={size || "sm"}
          src={avatar}
          name={name}
          {...props}
        ></Avatar>
      </MenuButton>
      <MenuList>
        <Box px={3} fontSize="sm">
          <Text fontWeight={"bold"}>{name}</Text>
          <Text>{email}</Text>
        </Box>
        <MenuDivider />
        <MenuItem as={Link} to="/settings">
          Settings
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={async () => {
            await clientApi.post("/users/logout");
            sessionStorage.removeItem("user-data");
            window.location.reload();
          }}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
