import { Grid } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "~/ui/Layout/Footer";
import Header from "~/ui/Layout/Header";
import { useOptionalUser } from "~/utils";

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useOptionalUser();

  useEffect(() => {
    if (user === undefined) {
      return;
    } else if (user === null) {
      let query = "";
      let path = location.pathname;
      if (path && path.length > 1) {
        let params = new URLSearchParams();
        params.set("next", path);
        query = "?" + params.toString();
      }
      navigate("/signin" + query);
    }
  }, [user, location]);

  return (
    <Grid templateRows="65px 1fr 73px" minH="100vh">
      <Header user={user} maxWidth={"6xl"} />
      <Outlet />
      <Footer />
    </Grid>
  );
}
