import {
  Box,
  Code,
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import CodeWithCopyButton from "~/ui/DataDisplay/CodeWithCopyButton";
import { H1 } from "../ui";
export default function MacInstallation() {
  return (
    <Box>
      <Helmet>
        <title>Install Sath for MacOS</title>
      </Helmet>
      <H1>Installing Sath for MacOS</H1>
      <OrderedList spacing={8}>
        <ListItem>
          Download Sath installation binary for your kernel architecture
          <Tabs as={Code} mt="4">
            <TabList color="teal.500">
              <Tab fontSize={"xs"} w="32">
                Arm
              </Tab>
              <Tab fontSize={"xs"} w="32">
                Intel
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p="0">
                <CodeWithCopyButton>
                  wget -O sath.run
                  https://download.sath.run/install/sath_darwin_arm64.run
                </CodeWithCopyButton>
              </TabPanel>
              <TabPanel p="0">
                <CodeWithCopyButton>
                  wget -O sath.run
                  https://download.sath.run/install/sath_darwin_amd64.run
                </CodeWithCopyButton>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ListItem>
        <ListItem>
          <Stack spacing={2}>
            <Text>Install Sath in your local machine</Text>
            <CodeWithCopyButton>sudo bash sath.run</CodeWithCopyButton>
          </Stack>
        </ListItem>
        <ListItem>
          <Stack spacing={2}>
            <Text>
              Check Sath version to verify Sath was installed correctly
            </Text>
            <CodeWithCopyButton>sath version</CodeWithCopyButton>
            <Text>
              The output should be something similar to:{" "}
              <Code px="2">Sath 1.0.0</Code>
            </Text>
          </Stack>
        </ListItem>
      </OrderedList>
    </Box>
  );
}
