import {
  Box,
  Code,
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import CodeWithCopyButton from "~/ui/DataDisplay/CodeWithCopyButton";
import { H1 } from "../ui";
export default function LinuxInstallation() {
  return (
    <Box>
      <Helmet>
        <title>Install Sath for Linux</title>
      </Helmet>
      <H1>Installing Sath for Linux</H1>
      <OrderedList spacing={8}>
        <ListItem>
          <Stack spacing={2}>
            <Text>
              Download Sath installation binary for your kernel architecture
            </Text>
            <Tabs as={Code} mt="2">
              <TabList color="teal.500">
                <Tab fontSize={"sm"} w="32">
                  x86_64
                </Tab>
                <Tab fontSize={"sm"} w="32">
                  arm64
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p="0">
                  <CodeWithCopyButton>
                    wget -O sath.run
                    https://download.sath.run/install/sath_linux_amd64.run
                  </CodeWithCopyButton>
                </TabPanel>
                <TabPanel p="0">
                  <CodeWithCopyButton>
                    wget -O sath.run
                    https://download.sath.run/install/sath_linux_arm64.run
                  </CodeWithCopyButton>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Text mt="2">
              If you are not sure which architecture your kernel is, you can run
              the following command in your terminal:
            </Text>
            <CodeWithCopyButton>uname -m</CodeWithCopyButton>
          </Stack>
        </ListItem>
        <ListItem>
          <Stack spacing={2}>
            <Text>Install Sath in your local machine</Text>
            <CodeWithCopyButton>sudo bash sath.run</CodeWithCopyButton>
          </Stack>
        </ListItem>
        <ListItem>
          <Stack spacing={2}>
            <Text>
              Check Sath version to verify Sath was installed correctly
            </Text>
            <CodeWithCopyButton>sath version</CodeWithCopyButton>
            <Text>
              The output should be something similar to:{" "}
              <Code px="2">Sath 1.0.0</Code>
            </Text>
          </Stack>
        </ListItem>
      </OrderedList>
    </Box>
  );
}
