import {
  Box,
  Code,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import CodeWithCopyButton from "~/ui/DataDisplay/CodeWithCopyButton";
import { H1, H2 } from "../ui";

export default function LinuxNvidiaContainerRuntime() {
  return (
    <Box mb={8}>
      <Helmet>
        <title>NVIDIA Container Toolkit</title>
      </Helmet>
      <H1>NVIDIA Container Toolkit</H1>
      <Stack spacing={2}>
        <Text>
          This chapter is optional. If your device does not use a NVIDIA
          graphics card, you can safely skip this step.
        </Text>
        <Text>
          However, if your device utilizes a NVIDIA graphics card, it&apos;s
          highly recommended to install and config the NVIDIA container runtime,
          so that Sath and Docker can make use of your GPU hardware to
          accellerate the running speed of some scientific algorithms.
        </Text>
        <Text>
          For complete installation guides, please refer to{" "}
          <Link
            href="https://docs.nvidia.com/datacenter/cloud-native/container-toolkit/latest/install-guide.html"
            color={"blue.600"}
            target="_blank"
          >
            NVIDIA official documentation
          </Link>
          . Here, we picks the steps for the generally used Linux environment.
        </Text>
      </Stack>
      <H2>Installing NVIDIA Container Toolkit with Apt</H2>
      <OrderedList spacing={8} mt={4}>
        <ListItem>
          <Text>Configure the production repository:</Text>
          <CodeWithCopyButton mt={2}>
            {
              "curl -fsSL https://nvidia.github.io/libnvidia-container/gpgkey | sudo gpg --dearmor -o /usr/share/keyrings/nvidia-container-toolkit-keyring.gpg \
  && curl -s -L https://nvidia.github.io/libnvidia-container/stable/deb/nvidia-container-toolkit.list | \
    sed 's#deb https://#deb [signed-by=/usr/share/keyrings/nvidia-container-toolkit-keyring.gpg] https://#g' | \
    sudo tee /etc/apt/sources.list.d/nvidia-container-toolkit.list"
            }
          </CodeWithCopyButton>
        </ListItem>
        <ListItem>
          <Text>Update the packages list from the repository:</Text>
          <CodeWithCopyButton mt={2}>sudo apt-get update</CodeWithCopyButton>
        </ListItem>
        <ListItem>
          Install the NVIDIA Container Toolkit packages:
          <CodeWithCopyButton mt={2}>
            sudo apt-get install -y nvidia-container-toolkit
          </CodeWithCopyButton>
        </ListItem>
      </OrderedList>
      <H2>Configuring Docker</H2>
      <OrderedList spacing={8} mt={4}>
        <ListItem>
          <Text>
            Configure the container runtime by using the <Code>nvidia-ctk</Code>{" "}
            command:
          </Text>
          <CodeWithCopyButton mt={2}>
            sudo nvidia-ctk runtime configure --runtime=docker
          </CodeWithCopyButton>
          <Text mt={2}>
            The <Code>nvidia-ctk</Code> command modifies the{" "}
            <Code>/etc/docker/daemon.json</Code> file on the host. The file is
            updated so that Docker can use the NVIDIA Container Runtime.
          </Text>
        </ListItem>
        <ListItem>
          Restart the Docker daemon:
          <CodeWithCopyButton mt={2}>
            sudo systemctl restart docker
          </CodeWithCopyButton>
        </ListItem>
      </OrderedList>
      <H2>Check NVIDIA Container Toolkit installation</H2>
      <OrderedList spacing={8} mt={4}>
        <ListItem>
          Run a sample CUDA container:
          <CodeWithCopyButton mt={2}>
            sudo docker run --rm --runtime=nvidia --gpus all ubuntu nvidia-smi
          </CodeWithCopyButton>
          <Text mt={2}>Your output should resemble the following output:</Text>
          <Code w={"100%"} p={2}>
            {`+-----------------------------------------------------------------------------+
            | NVIDIA-SMI 535.86.10 Driver Version: 535.86.10 CUDA Version: 12.2
            |
            |-------------------------------+----------------------+----------------------+
            | GPU Name Persistence-M| Bus-Id Disp.A | Volatile Uncorr. ECC | |
            Fan Temp Perf Pwr:Usage/Cap| Memory-Usage | GPU-Util Compute M. | |
            | | MIG M. |
            |===============================+======================+======================|
            | 0 Tesla T4 On | 00000000:00:1E.0 Off | 0 | | N/A 34C P8 9W / 70W |
            0MiB / 15109MiB | 0% Default | | | | N/A |
            +-------------------------------+----------------------+----------------------+
            +-----------------------------------------------------------------------------+
            | Processes: | | GPU GI CI PID Type Process name GPU Memory | | ID
            ID Usage |
            |=============================================================================|
            | No running processes found |
            +-----------------------------------------------------------------------------+`
              .split("\n")
              .map((line, i) => (
                <Text key={i}>
                  {line}
                  <br />
                </Text>
              ))}
          </Code>
        </ListItem>
      </OrderedList>
    </Box>
  );
}
