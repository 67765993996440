import { createBrowserRouter, redirect } from "react-router-dom";
import { OperatingSystem, getOperatingSystem } from "~/utils";
import ConsoleLayout from "./console/layout";
import Settings from "./console/settings";
import ErrorPage from "./error-page";
import Home from "./home";
import About from "./home/about";
import Introduction from "./home/introduction";
import Layout from "./home/layout";
import Product from "./home/product";
import SignIn from "./home/signin";
import SignUp from "./home/signup";
import TutorialLayout from "./tutorial/layout";
import LinuxDockerInstallation from "./tutorial/linux/docker";
import LinuxInstallation from "./tutorial/linux/install";
import LinuxNvidiaContainerRuntime from "./tutorial/linux/nvidia";
import LinuxRunSath from "./tutorial/linux/run";
import MacDockerInstallation from "./tutorial/mac/docker";
import MacInstallation from "./tutorial/mac/install";
import WindowsInstallation from "./tutorial/windows/install";
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/introduction",
        element: <Introduction />,
      },
      {
        path: "/product",
        element: <Product />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/",
        index: true,
        element: <Home />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    element: <ConsoleLayout />,
    children: [
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
  {
    element: <ConsoleLayout />,
    children: [
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
  {
    element: <TutorialLayout />,
    path: "/tutorial",
    children: [
      {
        path: "linux/install",
        element: <LinuxInstallation />,
      },
      {
        path: "linux/docker",
        element: <LinuxDockerInstallation />,
      },
      {
        path: "linux/nvidia",
        element: <LinuxNvidiaContainerRuntime />,
      },
      {
        path: "linux/run",
        element: <LinuxRunSath />,
      },
      {
        path: "macos/install",
        element: <MacInstallation />,
      },
      {
        path: "macos/docker",
        element: <MacDockerInstallation />,
      },
      {
        path: "macos/run",
        element: <LinuxRunSath />,
      },
      {
        path: "windows/install",
        element: <WindowsInstallation />,
      },
      {
        path: "*",
        index: true,
        loader: async function () {
          let os = getOperatingSystem();
          switch (os) {
            case OperatingSystem.Windows:
              return redirect("/tutorial/windows/install");
            case OperatingSystem.MacOS:
              return redirect("/tutorial/macos/install");
            default:
              return redirect("/tutorial/linux/install");
          }
        },
        element: null,
      },
    ],
  },
]);

export default router;
