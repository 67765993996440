import axios from "axios";

const getBaseUrl = () => {
  if (process.env.REACT_APP_URL) {
    return process.env.REACT_APP_URL;
  }
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://api.sath.run";
      break;
    case "development":
    default:
      url = "http://localhost:8080";
  }

  return url;
};

const clientApi = axios.create({
  baseURL: getBaseUrl() + "/client/v1",
  withCredentials: true,
});

export { clientApi };
