import { Box, Code, Link, ListItem, OrderedList, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { H1, H2 } from "../ui";
import { Link as RouterLink } from "react-router-dom";
import CodeWithCopyButton from "~/ui/DataDisplay/CodeWithCopyButton";

export default function LinuxRunSath() {
  return (
    <Box mb={8}>
      <Helmet>
        <title>Run your first Sath job</title>
      </Helmet>
      <H1>Run your first Sath job</H1>
      <H2>Register Sath account</H2>
      <Text mt={2}>
        If you have not registered your Sath account, please follow this{" "}
        <Link as={RouterLink} color={"blue.600"} to="/signup" target="_blank">
          link
        </Link>{" "}
        to do so. After your device successfully finishes a job, your account
        will earn points from Sath.
      </Text>
      <H2>Run Sath</H2>
      <OrderedList spacing={8} mt={4}>
        <ListItem>
          Startup Sath
          <CodeWithCopyButton mt={2}>sath startup</CodeWithCopyButton>
        </ListItem>
        <ListItem>
          Login your Sath account
          <CodeWithCopyButton mt={2}>sath login</CodeWithCopyButton>
          <Text mt={2}>Then type your email and password</Text>
        </ListItem>
        <ListItem>
          Check your Sath status
          <CodeWithCopyButton mt={2}>sath status</CodeWithCopyButton>
          <Text mt={2}>The output should be something like:</Text>
          <Code p={4} mt={2} w="100%">
            sath version: 1.0.0
            <br />
            *****************************************
            <br />
            sath-engine is logged in by user:
            <br />
            email: youremail@example.com
            <br />
            name: Your Name
            <br />
            *****************************************
            <br />
            sath-engine is waiting
            <br />
            &nbsp;&nbsp;use `sath run` to run jobs
            <br />
          </Code>
        </ListItem>
        <ListItem>
          Run Sath
          <CodeWithCopyButton mt={2}>sath run</CodeWithCopyButton>
        </ListItem>
        <ListItem>
          View your current running jobs
          <CodeWithCopyButton mt={2}>sath jobs</CodeWithCopyButton>
        </ListItem>
      </OrderedList>
    </Box>
  );
}
