import { Box, Text, Link as RemixLink } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { ExternalLink, H1 } from "../ui";
import { Link } from "react-router-dom";

export default function WindowsInstallation() {
  return (
    <Box>
      <Helmet>
        <title>Install Sath for Windows</title>
      </Helmet>
      <H1>Installing Sath for Windows</H1>
      <Text fontWeight={"semibold"}>
        Sath Destop for Windows is coming soon.
      </Text>
      <Text mt={2}>
        To try Sath now, you can{" "}
        <ExternalLink href="https://learn.microsoft.com/en-us/windows/wsl/install">
          install Linux on Windows with WSL
        </ExternalLink>{" "}
        and follow the tutorial to{" "}
        <RemixLink as={Link} color={"blue.600"} to="/tutorial/linux/install">
          install Sath on your WSL Linux
        </RemixLink>
        .
      </Text>
    </Box>
  );
}
