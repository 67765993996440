import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  ChakraProps,
  Code,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { useSuccessToast } from "~/utils/toast";

export default function CodeWithCopyButton({
  children,
  ...rest
}: {
  children?: string;
} & ChakraProps) {
  const toast = useSuccessToast();
  return (
    <Flex
      as={Code}
      px="4"
      py="2"
      alignItems={"center"}
      justifyContent={"space-between"}
      {...rest}
    >
      <Box>
        <Text
          pr="2"
          as="span"
          color="red.600"
          fontWeight={"semibold"}
          userSelect={"none"}
        >
          $
        </Text>
        {children}
      </Box>
      <IconButton
        ml={2}
        size={"sm"}
        aria-label="copy command to clipboard"
        icon={<CopyIcon />}
        onClick={async () => {
          try {
            await window.navigator.clipboard.writeText(children || "");
            toast("copied");
          } catch (e) {
            // TODO
          }
        }}
      />
    </Flex>
  );
}
