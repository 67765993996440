import {
  Box,
  ChakraProps,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { Link, matchPath } from "react-router-dom";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "~/ui/Layout/Footer";
import Header from "~/ui/Layout/Header";
import { OperatingSystem, getOperatingSystem, useOptionalUser } from "~/utils";

interface NavItemProps {
  text: string;
  link: string;
  isActive?: boolean;
}

export default function Layout() {
  const user = useOptionalUser();
  const [tabIndex, setTabIndex] = useState(0);
  let location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.startsWith("/tutorial/macos")) {
      setTabIndex(1);
    } else if (location.pathname.startsWith("/tutorial/windows")) {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  }, [location.pathname]);

  const handleTabsChange = (index: number) => {
    if (index === tabIndex) {
      return;
    }
    if (index === 1) {
      navigate("/tutorial/macos/install");
    } else if (index === 2) {
      navigate("/tutorial/windows/install");
    } else {
      navigate("/tutorial/linux/install");
    }
  };

  return (
    <Grid templateRows="65px 1fr 73px" minH="100vh">
      <Header user={user} maxWidth={"6xl"} />
      <Flex
        as={Container}
        flexDir={"row"}
        maxW={"6xl"}
        textAlign={"left"}
        my={8}
        gap={"8"}
      >
        <Box
          position={"sticky"}
          top={"97px"}
          flex={"0 0 260px"}
          alignSelf={"flex-start"}
          color={"teal.400"}
          userSelect={"none"}
        >
          <Tabs
            variant="soft-rounded"
            size={"sm"}
            colorScheme="teal"
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabList>
              <Tab w="20">Linux</Tab>
              <Tab w="20">MacOS</Tab>
              <Tab w="20">Windows</Tab>
            </TabList>
            <Divider mt={2} />
            <TabPanels mt={4}>
              <LinuxNavs />
              <MacOSNavs />
              <WindowsNavs />
            </TabPanels>
          </Tabs>
        </Box>
        <Box flexGrow={"1"}>
          <Outlet />
        </Box>
      </Flex>
      <Footer />
    </Grid>
  );
}

function LinuxNavs() {
  return (
    <TabPanel as={Stack} spacing={"4"}>
      <NavItem link="/tutorial/linux/install">Install Sath</NavItem>
      <NavItem link="/tutorial/linux/docker">Install Docker</NavItem>
      <NavItem link="/tutorial/linux/nvidia">
        Config NVIDIA Container Runtime (optional)
      </NavItem>
      <NavItem link="/tutorial/linux/run">Run your first Sath job</NavItem>
    </TabPanel>
  );
}

function MacOSNavs() {
  return (
    <TabPanel as={Stack} spacing={"4"}>
      <NavItem link="/tutorial/macos/install">Install Sath</NavItem>
      <NavItem link="/tutorial/macos/docker">Install Docker</NavItem>
      <NavItem link="/tutorial/macos/run">Run your first Sath job</NavItem>
    </TabPanel>
  );
}

function WindowsNavs() {
  return (
    <TabPanel as={Stack} spacing={"4"}>
      <NavItem link="/tutorial/windows/install">Install Sath</NavItem>
    </TabPanel>
  );
}

function NavItem({
  children,
  _hover,
  link,
  ...rest
}: ChakraProps & { children: ReactNode; link: string }) {
  let location = useLocation();
  let match = matchPath({ path: link, end: false }, location.pathname);
  let activeColor = "teal.900";
  return (
    <Text
      as={Link}
      to={link}
      color={match ? activeColor : undefined}
      _hover={{
        color: activeColor,
        cursor: "pointer",
      }}
      {...rest}
    >
      {children}
    </Text>
  );
}
