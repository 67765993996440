import {
  Button,
  Grid,
  Link,
  VisuallyHidden,
  useColorModeValue,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import Footer from "~/ui/Layout/Footer";
import Header from "~/ui/Layout/Header";
import { useOptionalUser } from "~/utils";

export default function Layout({ children }: PropsWithChildren) {
  const user = useOptionalUser();
  return (
    <Grid templateRows="65px 1fr 73px" minH="100vh">
      <Header user={user} maxWidth="6xl" />
      <Outlet />
      <Footer />
    </Grid>
  );
}
