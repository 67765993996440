import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  Input,
  InputGroup,
  InputProps,
} from "@chakra-ui/react";
import React from "react";

export interface FormInputProps
  extends InputProps,
    Pick<React.HTMLProps<HTMLButtonElement>, "autoComplete"> {
  name: string;
  label?: string;
  container?: FormControlProps;
  formId?: string;
  error?: string;
}

const FormInput = forwardRef<FormInputProps, "input">((props, ref) => {
  const {
    id,
    name,
    label,
    children,
    as,
    container,
    size,
    isDisabled,
    isRequired,
    error,
    ...rest
  } = props;
  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} {...container}>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <Box
          id={id || name}
          name={name}
          ref={ref}
          as={as || Input}
          size={size}
          isDisabled={isDisabled}
          {...rest}
        />
        {children}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
});

export default FormInput;
