import {
  Box,
  Code,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import CodeWithCopyButton from "~/ui/DataDisplay/CodeWithCopyButton";
import { ExternalLink, H1 } from "../ui";

export default function MacDockerInstallation() {
  return (
    <Box>
      <Helmet>
        <title>Install Docker</title>
      </Helmet>
      <H1>Installing Docker</H1>
      <Text mb={8}>
        You need to install{" "}
        <ExternalLink href="https://www.docker.com">Docker</ExternalLink> in
        order to run your sath job. Sath uses Docker as the container runtime to
        pull, manage and execute scientific algorithms.
      </Text>
      <OrderedList spacing={8}>
        <ListItem>
          <Text>Install Docker Desktop</Text>
          <Text>
            Please refer to{" "}
            <ExternalLink href="https://docs.docker.com/desktop/install/mac-install/">
              {"Docker's official documentation"}
            </ExternalLink>{" "}
            for installation steps for your operating system.
          </Text>
        </ListItem>
        <ListItem>
          <Stack spacing={2}>
            <Text>Check Docker installation</Text>
            <CodeWithCopyButton>docker run hello-world</CodeWithCopyButton>
            <Text>
              If your Docker is installed properly, the output should be
              something like
            </Text>
            <Code px="4" py="2">
              Hello from Docker!
              <br />
              This message shows that your installation appears to be working
              correctly.
              <br />
              <br />
              ...
            </Code>
          </Stack>
        </ListItem>
      </OrderedList>
    </Box>
  );
}
